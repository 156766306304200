import React        from "react";
import RegisterForm from './RegisterForm';



// our react component
const Content = () => {
	return (
		<div className="container-content">
			<div className="col-half">Diversity in Development
				<Disclaimer/>
			</div>
			<div className="border-line"></div>
			<div className="col-half">Get in touch
				<RegisterForm/>
			</div>
		</div>
	);

};


const Disclaimer = () =>{
	return(
		<div className="body-copy">
			Crest Projects is a boutique development company based in Geelong,
			Victoria that takes a specialised approach to asset repurposing and
			transformation via innovative solutions aimed at accommodating
			today’s living needs. Diversity in development is the premise
			behind each project as we strive to embrace the modern demographic.
			<AgentInfo/>
		</div>
	)
};


const AgentInfo = () => {
	return (
		<div>
			<div className="body-post-title" >
				David Costa
				<div className="body-post-info">
					<span className="body-post-span">Director -</span> 0412 990 241
				</div>
			</div>
			<div className="body-post-title" >
				Darren Thomson
				<div className="body-post-info">
					<span className="body-post-span">Director -</span> 0411 869 497
				</div>
			</div>
		</div>
	)
};

// export the newly generated component
export default Content;