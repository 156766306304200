import React       from "react";
import footerWave from '../assets/footer-wave.png';
import footerText  from '../assets/footer-text.svg';



// our react component
const Footer = () => {
	return (
		<div className="container-footer">
			<Address/><FooterImages/>
		</div>
	);

};


const Address = () =>{
	return(
		<div className="footer-address width50">
			174 Moorabool Street, Geelong, Vic, 3220. <br className="breaker"/> PO Box 303 Geelong, Vic, 3220
		</div>
	)
};

const FooterImages = () =>{
	return(
		<div className="footer-images width50">
			<img src={footerWave} className="logo-footer-wave" alt="logo" />
			<img src={footerText} className="logo-footer-text" alt="logo" />
		</div>
	)
};

// export the newly generated component
export default Footer;