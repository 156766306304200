//require mandrill library and instantiate the mandrill class
var mandrill = require('mandrill-api/mandrill');
var mandrill_client = new mandrill.Mandrill('Dzsq9YFQxD3I2O5RLPy_aw');


function sendEmail (emailRecipient1, emailRecipient2, mergeVariables, onSuccessCallback, onFailCallback) {

	/* taking the form variables and creating a return object with the "name" and "content" format
	 consistent with the required template content*/
	let entries = Object.entries(mergeVariables);
	var output = entries.map(function(kvArray){
		return {
			"name": kvArray[0].toUpperCase(),
			"content": kvArray[1]
		}
	});

	var params = {
		"template_name": "crest_register_template",
		"template_content": [],
		"message": {
			"from_email":"noreply@contactservice.com.au",
			"to":[{"email":emailRecipient1},{"email":emailRecipient2}],
			"subject": "Crest Project Enquiry",
			"type": "to",
			"merge_language": "mailchimp",
			"global_merge_vars": output,
		}
	};

	mandrill_client.messages.sendTemplate(params, onSuccessCallback, onFailCallback);
}

export default sendEmail;