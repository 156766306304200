import React from "react";
import logoDesktop  from '../assets/crest-desktop.png';
import logoMobile  from '../assets/crest-mobile.png';


// our react component
const Topbar = () => {
	return (
		<div className="container-topbar">
				<div className="col-full">
					<img src={logoDesktop} className="logo-desktop" alt="logo" />
					<img src={logoMobile} className="logo-mobile" alt="logo" />
				</div>
		</div>
	);

};

// export the newly generated component
export default Topbar;