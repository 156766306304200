import "@babel/polyfill";
import React from 'react';
import "./App.scss";
import Topbar             from './components/Topbar';
import Content            from './components/Content';
import Footer            from './components/Footer';



function App() {
  return (
    <div className="App">
      <Topbar></Topbar>
      <Content></Content>
      <Footer></Footer>
    </div>
  );
}

export default App;
