import React       from "react";
import sendEmail from '../Mandrill';


const hideModal = () => {
	var modal = document.querySelector('.form-register');
	var message = document.querySelector('.form-success');

	modal.style.display = 'none';
	message.style.display = 'block';
};


class RegisterForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fName: "",
			lName: "",
			email: "",
			phone: "",
			comment: "",
			isFormValid: false,
			showErrorFirst: false,
			showErrorLast: false,
			showErrorPhone: false,
			showErrorEmail: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.formSubmitHandler = this.formSubmitHandler.bind(this);

	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	formSubmitHandler = (e) => {
		e.preventDefault()

		let fName = this.state.fName;
		let lName = this.state.lName;
		let phone = this.state.phone;
		let email = this.state.email;
		let comment = this.state.comment;
		let validResponse = [];

		let phoneRegex = /^[0-9+()\- ]+$/;
		function testInfo(phoneInput) {
			var OK = phoneRegex.exec(phoneInput);
			if (OK) {
				return validResponse;
			}
		}
			if(fName.length > 1 && typeof fName === "string") {
				validResponse.push(fName);
				let newState = Object.assign(this.state, {
					showErrorFirst: false
				});
				this.setState(newState);
			} else {
				let newState = Object.assign(this.state, {
					showErrorFirst: true
				});
				this.setState(newState);
			}

			if(lName.length > 1 && typeof lName === "string") {
				validResponse.push(lName);
				let newState = Object.assign(this.state, {
					showErrorLast: false
				});
				this.setState(newState);
			} else {
				let newState = Object.assign(this.state, {
					showErrorLast: true
				});
				this.setState(newState);
			}

			if(phone.length > 6) {
				testInfo();
				validResponse.push(phone);
				let newState = Object.assign(this.state, {
					showErrorPhone: false
				});
				this.setState(newState);
			} else {
				let newState = Object.assign(this.state, {
					showErrorPhone: true
				});
				this.setState(newState);
			}

			if(email.indexOf("@") !== -1 ) {
				validResponse.push(email);
				let newState = Object.assign(this.state, {
					showErrorEmail: false
				});
				this.setState(newState);
			} else {
				let newState = Object.assign(this.state, {
					showErrorEmail: true
				});
				this.setState(newState);
			}

			validResponse.push(comment);

			if(validResponse.length >= 5) {
				let newState = Object.assign(this.state, {
					isFormValid: true
				});
				var mergeObject = {
					fName,
					lName,
					phone,
					email,
					comment
				};

				sendEmail('david@crestprojects.com.au', 'darren@crestprojects.com.au', mergeObject, function(res){
					console.log(res)
				}, function(error){
					console.log(error)
				});
				hideModal();

				this.setState(newState);
			}

			if(validResponse.length <= 4) {
				let newState = Object.assign(this.state, {
					isFormValid: false
				});
				this.setState(newState);
			}
	}




	render() {
		return (
			<div className="container-register">
				<form className="form-register">
					<div className="form-input-top">
						<input name="fName" className={`form-input-first ${this.state.showErrorFirst}`} type="text" placeholder="First Name" value={this.state.fName} required onChange={(e) => this.handleInputChange(e , "text")} />
						<input name="lName" className={`form-input-last ${this.state.showErrorLast}`} type="text" placeholder="Last Name" value={this.state.lName} required onChange={(e) => this.handleInputChange(e , "text")} />
					</div>
					<input name="phone" className={`form-input-phone ${this.state.showErrorPhone}`} type="tel" placeholder="Phone" value={this.state.phone} required onChange={(e) => this.handleInputChange(e , "text") } />
					<input name="email" className={`form-input-email ${this.state.showErrorEmail}`} type="email" placeholder="Email" value={this.state.email} required onChange={(e) => this.handleInputChange(e , "email")} />
					<textarea name="comment" className={"form-input-comment"} type="text" placeholder="Comment" value={this.state.comment} onChange={(e) => this.handleInputChange(e , "text")} />
					<div className="form-post-form">
						<SubmitButton type="button" onClick={this.formSubmitHandler}>Submit</SubmitButton>
					</div>
				</form>
				<div className="form-success">
					Thank you for your interest. <br/>
					We will be in touch with you shortly.
				</div>
			</div>
		);
	}
}


const SubmitButton = (props) => {
	return (
		<button className="form-submit-button" type={props.type} onClick={props.onClick}>{props.children}</button>
	)
};


// export the newly generated component
export default RegisterForm;